<template>
  <v-container :class="getClassRelative()+' '+ getClassRight()"  id="pdfInvoice" style="top:2cm">
    <v-row :class="getClassAbsolute()+' '+ getClassRight()" style="top:2cm; ">
      <v-col style="text-align: right; ">
        <h3 style="font-weight: bold">Rechnung </h3>
      </v-col>
    </v-row>
    <v-row :class="getClassAbsolute()" style=" top:2cm; left: 2.4cm" v-if="profilePic && profilePic !== ''">
      <v-col>
        <img :src="profilePic" style="max-height: 2.2cm; max-width: 4.2cm">
      </v-col>
    </v-row>
    <v-row :class="getClassAbsolute()" style=" top:4.5cm;">
      <v-col>
        <small style="font-size: 11px"><b><span v-if="invoiceSettings.company">{{ invoiceSettings.company }} -</span> {{ invoiceSettings.name }}</b> |
          {{ invoiceSettings.street }} | {{ invoiceSettings.city }}
        </small><br>
        {{ invoice.company }}<br>
        {{ invoice.name }}<br>
        {{ invoice.street }}<br>
        {{ invoice.city }}<br> <br><br><br>
      </v-col>
    </v-row>
    <div :class="getClassAbsolute()+' '+ getClassRight()" style=" top:10cm; left:2.5cm">
      <v-row>
        <p>{{ invoice.header }}</p> <br><br>
      </v-row>
      <v-row>
        <v-col>
          <h5>Betreff</h5>
          {{ invoice.subject }}
        </v-col>
        <v-col>
          <h5>Rechnungsnummer</h5>
          {{ invoice.invoiceNumber }}
        </v-col>
        <v-col>
          <h5>Leistungs- / Lieferzeitraum </h5>
          {{ invoice.date }}
        </v-col>
        <v-col>
          <h5>Rechnungsdatum</h5>
          {{ getStringDate(invoice.invoiceDate) }}
        </v-col>
      </v-row>
      <v-row>
        <table width="100%" class="pa-3">
          <thead style="font-weight: bold">
          <tr>
            <td>
              Beizeichnung
            </td>
            <td>
              Preis
            </td>
            <td>
              Menge
            </td>
            <td>
              Einheit
            </td>
            <td>
              Steuersatz
            </td>
            Summe (brutto)
          </tr>
          </thead>
          <tbody>
          <tr v-for="position of positions" :key="position.title">
            <td>
              {{ position.title }}<br v-if="position.description && position.description !==''">
              <span style="font-size: 12px" v-if="position.description && position.description !==''">{{position.description}}</span>
            </td>
            <td>
              {{ replaceDot(position.price) }}€
            </td>
            <td>
              {{ position.quant }}
            </td>
            <td>
              {{ position.einheit }}
            </td>
            <td>
              {{ position.steuersatz }}%
            </td>
            <td>
              {{ replaceDot(position.sum) }}€
            </td>
          </tr>
          </tbody>
        </table>
      </v-row>
      <v-row style="text-align: right; padding-top: 0.2cm">
        <v-col>
          <p style="margin: 0 0">Gesamtpreis (netto): {{ replaceDot(getSumFromPositionsNetto()) }}€</p>
          <p style="margin: 0 0">Umsatzsteuer: {{ replaceDot(getSumFromPositions() - getSumFromPositionsNetto()) }}€</p>
          <p style="margin: 0 0">Gesamtpreis (brutto): {{ replaceDot(getSumFromPositions()) }}€</p>
        </v-col>

      </v-row>

      <v-row v-if="invoiceSettings.kleinunternehmerB">
        <p>{{ invoiceSettings.kleinunternehmer }}</p>
      </v-row>
      <v-row>
        <p>{{ invoice.footer }}</p>
      </v-row>
      <v-row style="font-size: 13px; padding-top: 1.7cm">
        <v-col style="width: 10cm">
          <p style="margin: 0 0">
            <b style="font-weight: bold" v-if="invoiceSettings.company">{{ invoiceSettings.company }} <br></b>
            {{ invoiceSettings.name }}<br>
            {{ invoiceSettings.street }} <br>
            {{ invoiceSettings.city }}
          </p>
            <p style="margin: 0 0">
              <span v-if="invoiceSettings.ustId">USt-ID: {{ invoiceSettings.ustId }}</span>
              <br>
              <span v-if="invoiceSettings.steuernummer">Steuernummer: {{ invoiceSettings.steuernummer }}</span>
            </p>

        </v-col>
        <v-col>
          <small>
            <b style="font-weight: bold">Kontaktdaten</b>
            <br>
            <span v-if="invoiceSettings.phone">Telefon: {{ invoiceSettings.phone }}</span>
            <br>
            <span v-if="invoiceSettings.email">E-Mail: {{ invoiceSettings.email }}</span>
          </small>
        </v-col>
        <v-col>
          <small>
            <b style="font-weight: bold">Bankverbindung</b>
            <br>
            <span v-if="invoiceSettings.Kontoinhaber">Kontoinhaber: {{ invoiceSettings.Kontoinhaber }}</span>
            <br>
            <span v-if="invoiceSettings.IBAN">IBAN: {{ invoiceSettings.IBAN }}</span>
            <br>
            <span v-if="invoiceSettings.BIC">BIC: {{ invoiceSettings.BIC }}</span>
          </small>
        </v-col>
      </v-row>
    </div>

  </v-container>
</template>
<script>
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
// import VueHtml2pdf from 'vue-html2pdf'
import {date2String, dotToKomma} from "@/helper/helper";
//const Doughnut = () => import("@/components/generalUI/charts/Doughnut");

export default {
  name: "GeneratePdf",
  components: {
    // VueHtml2pdf,
    // Doughnut
  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    /**
     * Contains data to display the alert accordingly
     */
    fileName: {
      type: String,
      default() {
        return ''
      }
    },
    invoiceSettings: {
      type: Object,
      default() {
        return {}
      }
    },
    invoice: {
      type: Object,
      default() {
        return {}
      }
    },
    positions: {
      type: Array,
      default() {
        return []
      }
    },
    print: {
      type: Boolean,
      default() {
        return true
      }
    },
    invoiceStatus: Number,
    profilePic: String
  },
  methods: {
    generateReport() {
      this.$emit('generated')
    },
    replaceDot(float) {
      return dotToKomma(float)
    },
    isZero(number) {
      if (number < 0) {
        return 0
      } else {
        return number
      }
    },
    getStringDate(date) {
      return date2String(date)
    },
    getSumFromPositions() {
      let sum = 0
      for (let position of this.positions) {
        sum = sum + position.sum
      }
      return sum
    },
    getSumFromPositionsNetto() {
      let sum = 0
      for (let position of this.positions) {
        sum = sum + position.price * position.quant
      }
      return sum
    },
    getClassAbsolute(){
      if(!this.print){
        return ''
      }else{
        return 'absoluteC'
      }
    },
    getClassRelative(){
      if(!this.print){
        return ''
      }else{
        return 'relativeC'
      }
    },
    getClassRight(){
      if(!this.print){
        return ''
      }else{
        return 'rightC'
      }
    },
  },
}
</script>

<style>

.absoluteC{
  position: absolute;

}
.relativeC{
  position: relative;

}
.rightC{
  right: 2.5cm

}
#pdfInvoice * {
  font-weight: unset;
  font-size: unset
}

h1 {
  font-weight: bold;
  font-size: larger;
  padding-top: 20px
}

h2 {
  font-weight: bold;
  font-size: unset;
}

@page {

}

.breakText {
  break-before: page;
  margin-top: 30px;
}
</style>
